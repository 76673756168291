import React from "react";

const Header = () => (
    <div className="page-footer grey lighten-4">
        <div className="footer-copyright">
            <div className="container center grey-text darken-4">&copy; 2023 by Stevany Carpentry</div>
            </div>
    </div>
);

export default Header;
