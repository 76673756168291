import React from "react";
import { Row, Col, Card } from 'react-materialize';
import Icon from '@mdi/react';
import { mdiChevronRight } from '@mdi/js';
import CuratorWidget from "../feed/curator-widget";

const Home = () => (
  <Row>
    <Col s={12}>
        <h5 className="subtitle light-blue-text lighten-3"><Icon size={1} path={mdiChevronRight} className="inline-icon" />Last Projects</h5>
        <Card>
            <Row>
                <CuratorWidget feedId="8dcaae2e-3f53-4206-a03a-b55275589073"/>
            </Row>
        </Card>
    </Col>
  </Row>
);

export default Home;
