import React from "react";
import { Row, Col, Card, Collection, CollectionItem } from 'react-materialize';
import Icon from '@mdi/react';
import { mdiHammer, mdiChevronRight, mdiContentCut, mdiHammerWrench, mdiSnowflake } from '@mdi/js';

const Services = () => (
  <Row>
    <Col m={12} s={12}>
        <h5 className="subtitle light-blue-text lighten-3"><Icon size={1} path={mdiChevronRight} className="inline-icon" title="Services" />Services</h5>
        <Card>
            <Collection className="without-border-collection">
                <CollectionItem className="avatar">
                    <Icon className="circle light-blue white-text" size={2} path={mdiHammer} title="Handyman" />
                    <span className="title">
                        Carpentry
                    </span>
                    <p>
                        Specializing in finish carpentry, we offer a full range of services including millwork and
                        design, custom built-ins and closets, as well as prefab installation.
                    </p>
                    <p>
                        We know how important your home or business is to you, and that’s why you can trust us with
                        your kitchens and bathrooms, from baseboard to molding and everything in between.
                    </p>
                </CollectionItem>
            </Collection>
        </Card>
    </Col>
  </Row>
);

export default Services;
