import React from "react";
import { Row, Col, Card } from 'react-materialize';
import Icon from '@mdi/react';
import { mdiChevronRight } from '@mdi/js';
import logo from '../../images/logo.png';

const AboutUs = () => (
  <Row>
    <Col m={12} s={12}>
        <h5 className="subtitle light-blue-text lighten-3"><Icon size={1} path={mdiChevronRight} className="inline-icon" />About Us</h5>
        <Card>
            <Row>
                <Col m={4} s={4}>
                    <img src={logo} className="responsive-img" />
                </Col>
                <Col m={8} s={8}>
                    <p>
                        With over 10 years of experience in carpentry, we know the meaning of quality craftsmanship.
                        We are family owned and operated which means that our business hinges on trust and integrity.
                    </p>
                    <br />
                    <p>
                        Offering a full range of project types from residential to commercial properties, construction
                        to renovation, we see the details and the big picture. We truly value each project, and that’s
                        why we will work with you to understand your vision, timeline, and budget.  Excellence is our
                        mission, satisfaction our guarantee.
                    </p>
                </Col>
            </Row>
        </Card>
    </Col>
  </Row>
);

export default AboutUs;
