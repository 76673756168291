import React from "react";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import Home from "./components/home/home";
import Services from "./components/services/services";
import AboutUs from "./components/about-us/about-us";
import ContactUs from "./components/contact-us/contact-us";
import { Container } from "react-materialize";
import { Route, Routes } from "react-router-dom";

const Main = () => (
    <main>
        <Header />
        <Container>
            <Routes>
                <Route exact path="/" element={<Home />}/>
                <Route path="/services" element={<Services />}/>
                <Route path="/about-us" element={<AboutUs />}/>
                <Route path="/contact-us" element={<ContactUs />}/>
                <Route path="*" element={<Home />}/>
            </Routes>
        </Container>
        <Footer />
    </main>  
);

export default Main;