import React from "react";
import { Row, Col, Card, Collection, CollectionItem } from 'react-materialize';
import Icon from '@mdi/react';
import { mdiInstagram, mdiEmail, mdiPhone, mdiChevronRight, mdiWhatsapp } from '@mdi/js';
import AdrianoAvatar from '../../images/adriano_avatar.png';
import NancyAvatar from '../../images/nancy_avatar.png';
import JhonAvatar from '../../images/jhon_avatar.png';

const ContactUs = () => (
  <Row>
    <Col m={12} s={12}>
        <h5 className="subtitle light-blue-text lighten-3"><Icon size={1} path={mdiChevronRight} className="inline-icon" title="Contact Us" />Contact Us</h5>
        <Card>
            <Collection className="without-border-collection">
                <CollectionItem className="avatar">
                    <img alt="Adriano Alviti" className="circle" src={AdrianoAvatar} />
                    <span className="title">
                        Adriano Alviti
                    </span>
                    <p>
                        <a href="tel:+17817209331"><Icon size={1} path={mdiPhone} className="inline-icon" /> (781) 720 9331</a>
                        <br />
                        <a href="https://api.whatsapp.com/send?phone=17817209331&text=Hi%20Stevany%20Carpentry%2C%20I'd%20like%20to%20know%20more%20about%20your%20services." title="Whatsapp"><Icon size={1} path={mdiWhatsapp} className="inline-icon" /> Whatsapp</a>
                    </p>
                </CollectionItem>
                <CollectionItem className="avatar">
                    <img alt="Jhon Stevany" className="circle" src={JhonAvatar} />
                    <span className="title">
                        Jhon Stevany
                    </span>
                    <p>
                        <a href="tel:+17814202802"><Icon size={1} path={mdiPhone} className="inline-icon" /> (781) 420-2802</a>
                        <br />
                        <a href="https://api.whatsapp.com/send?phone=17814202802&text=Hi%Stevany%20Carpentry%2C%20I'd%20like%20to%20know%20more%20about%20your%20services." title="Whatsapp"><Icon size={1} path={mdiWhatsapp} className="inline-icon" /> Whatsapp</a>
                    </p>
                </CollectionItem>
                <CollectionItem className="avatar">
                    <img alt="Nancy" className="circle" src={NancyAvatar} />
                    <span className="title">
                        Nancy Alviti
                    </span>
                    <p>
                        <a href="tel:+15083103955"><Icon size={1} path={mdiPhone} className="inline-icon" /> (508) 310-3955</a>
                        <br />
                        <a href="https://api.whatsapp.com/send?phone=15083103955&text=Hi%Stevany%20Carpentry%2C%20I'd%20like%20to%20know%20more%20about%20your%20services." title="Whatsapp"><Icon size={1} path={mdiWhatsapp} className="inline-icon" /> Whatsapp</a>
                    </p>
                </CollectionItem>
                <CollectionItem className="avatar">
                    <Icon size={2} path={mdiEmail} className="circle" />
                    <span className="title">
                        E-mail
                    </span>
                    <p>
                        <a href="mailto:contact@stevanycarpentry.com">contact@stevanycarpentry.com</a>
                    </p>
                </CollectionItem>
                <CollectionItem className="avatar">
                    <Icon size={2} path={mdiInstagram} className="circle" />
                    <span className="title">
                    Instagram
                    </span>
                    <p>
                        <a href="https://instagram.com/stevanycarpentry" target="_blank">@stevanycarpentry</a>
                    </p>
                </CollectionItem>
            </Collection>
        </Card>
    </Col>
  </Row>
);

export default ContactUs;
