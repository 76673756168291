import React from "react";
import { Navbar } from 'react-materialize';
import { NavLink } from 'react-router-dom'
import Icon from '@mdi/react';
import { mdiHome, mdiToolboxOutline, mdiAccountGroup, mdiPhone, mdiMenu } from '@mdi/js';
import logo from '../../images/logo.png';
import carousel1 from '../../images/carousel/1.png';
import carousel2 from '../../images/carousel/2.png';
import carousel3 from '../../images/carousel/3.png';
import carousel4 from '../../images/carousel/4.png';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const Header = () => (
    <div>
        <div>
            <Navbar 
                className="grey lighten-4"
                alignLinks="right"
                brand={<NavLink className="black-text" to="/"><img src={logo} className="logo responsive-img" /></NavLink>}
                menuIcon={<Icon size={1} path={mdiMenu} className="black-text" title="Home" />}
                options={{ closeOnClick: true }}
                centerChildren
            >
                <NavLink className="black-text inline-icon sidenav-close" to="/">
                    <Icon size={1} path={mdiHome} title="Home" /> Home
                </NavLink>
                <NavLink className="black-text sidenav-close" to="/services">
                    <Icon size={1} path={mdiToolboxOutline} title="Services" /> Services
                </NavLink>
                <NavLink className="black-text sidenav-close" to="/about-us">
                    <Icon size={1} path={mdiAccountGroup} title="About Us" /> About Us
                </NavLink>
                <NavLink className="black-text sidenav-close" to="/contact-us">
                    <Icon size={1} path={mdiPhone} title="Contact Us" /> Contact Us
                </NavLink>
            </Navbar>
    </div>
        <div>
            <Carousel 
                className="white-text center"
                autoPlay={true}
                showArrows={false}
                showStatus={false}
                showIndicators={false}
                showThumbs={false}
                dynamicHeight={true}
            >
                <div>
                    <img src={carousel1} />
                </div>
                <div>
                    <img src={carousel2} />
                </div>
                <div>
                    <img src={carousel3} />
                </div>
                <div>
                    <img src={carousel4} />
                </div>
            </Carousel>
        </div>
    </div>
);

export default Header;
